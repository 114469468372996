import styled from "styled-components";

export const InvoiceBox = styled.div`
  .fetching-loader {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .error {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ff3936;
  }
  .container {
    margin: 0px auto;
    width: 1280px;
  }
  background: rgba(232, 236, 246, 255);
  .pdf-container {
    height: 900px;
    margin-right: 8px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.07), 1px 1px 4px rgba(0, 0, 0, 0.06);
  }
  .react-pdf__Document {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: 900px !important;
    .react-pdf__Page__svg {
      width: 100% !important;
      height: auto !important;
      svg {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
  .hidden-pdf {
    position: absolute;
    visibility: hidden;
  }
  .main-header {
    background: white;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 15px 0px;
      .header-logo {
        display: flex;
        align-items: center;
      }
      .buttons-group {
        button {
          margin-right: 15px;
          height: 40px;
          img {
            height: 16px;
            margin-right: 8px;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .title {
    color: #1d2e54;
    font-size: 32px;
    font-weight: 500;
    font-family: Lab-Grotesque-Bold;
    padding: 20px 0px;
  }

  .aside-bar {
    .ant-form-item-label {
      label {
        color: #1d2e54;
        font-weight: 500;
      }
    }
    .aside-bar-top {
      background: white;
      padding: 15px;
      .invoice-btn {
        height: 50px;
        font-family: Lab-Grotesque-Bold;
        font-size: 16px;
        span {
          line-height: 46px;
        }
      }
      .payment {
        text-align: center;
        border: 1px solid rgba(226, 233, 242, 255);
        padding-bottom: 20px;
        .title {
          color: #828fa5;
          font-size: 14px;
          font-family: Lab-Grotesque-Regular;
          padding-top: 18px;
          padding-bottom: 9px;
        }
        .amount {
          color: #1d2e54;
          font-size: 32px;
          font-weight: bold;
        }
        .due-date {
          padding-top: 10px;
          color: #828fa5;
          font-family: Lab-Grotesque-Regular;
          font-size: 14px;
        }
      }
      .amount-form-item {
        margin-top: 20px;
        label {
          font-family: Lab-Grotesque-Medium;
        }
        input {
          font-family: Lab-Grotesque-Medium;
          color: #4a5b78;
          height: 40px;
        }
      }
      .ant-radio-group {
        width: 100%;
      }
      .pay-btn {
        height: 50px;
        font-family: Lab-Grotesque-Bold;
        font-size: 16px;
        span {
          line-height: 46px;
        }
      }
      .stripe-card {
        height: 0px;
        transition: all 0.3s ease-in;
      }
      .stripe-card-active {
        padding: 10px 0px 20px 0px;
        height: 50px;
        transition: all 0.3s ease-out;
      }
      .radio-card {
        width: 100%;
        display: flex;
        height: 40px;
        align-items: center;
        border: 1px solid black;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: "tnum", "tnum";
        position: relative;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
        margin-bottom: 14px;
        span {
          color: #4a5b78;
          font-weight: 500;
          font-size: 14px;
        }
        img {
          margin-left: 20px;
          object-fit: contain;
          height: 13px;
        }
        label {
          span {
            &:nth-child(2) {
              display: flex;
              align-items: center;
              white-space: pre;
            }
          }
        }
      }
      .aside-logo {
        padding-top: 20px;
        padding-bottom: 10px;
        text-align: center;
      }
    }
    .aside-bar-bottom {
      padding-top: 45px;
      .title {
        color: rgba(74, 91, 120, 255);
        font-size: 14px;
        margin-bottom: 10px;
      }
      .ticket {
        color: rgba(34, 51, 88, 255);
        font-weight: 500;
        padding: 10px 20px;
        background: white;
        margin-bottom: 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-side {
          display: flex;
          align-items: center;
          img {
            margin-right: 6px;
          }
        }
        .download-btn {
          cursor: pointer;
        }
      }
      .social {
        background: white;
        margin-bottom: 100px;
        border-radius: 5px;
        .logos {
          padding: 20px 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          border-bottom: 2px solid rgba(226, 233, 242, 255);
        }
        .link {
          padding: 15px 0px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
`;
