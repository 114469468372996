import React from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeBox, CheckoutBox } from "./_stripe.styled";
import { httpGet } from "utils";
import { Modal } from "antd";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "");

const CheckoutForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { documentId, uid, paymentAmount, setIsPaying } = props;
  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    setIsPaying(true);
    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement || { token: "" },
    });
    if (paymentMethodResponse?.error) {
      alert("Error: " + paymentMethodResponse?.error?.message);
      setIsPaying(false);
    } else {
      createPaymentIntent(paymentMethodResponse, (res: any) =>
        capturePayment(res)
      );
    }
  };

  /**
   * CreatePaymentIntent
   *
   * @param {Number} amount
   * @param {*} callback
   */
  const createPaymentIntent = async (
    paymentMethodResponse: any,
    callback: any
  ) => {
    let url =
      "https://us-central1-okason-contractor-app.cloudfunctions.net/startStripeCheckout";
    url =
      url +
      `?documentId=${documentId}&uid=${uid}&amount=${paymentAmount}&paymentId=${paymentMethodResponse?.paymentMethod?.id}`;
    await httpGet(url)
      .then((res) => {
        if (res?.error) {
          alert("Error: " + res.error?.raw?.message);
          setIsPaying(false);
        } else if (res) {
          callback(res);
        }
      })
      .catch((err) => {
        alert(err);
        setIsPaying(false);
      });
  };

  function paymentSuccessfulModal() {
    Modal.success({
      title: "Payment is successful",
    });
  }
  
  const capturePayment = async (res: any) => {
    if (res.status === "succeeded") {
      let url =
        "https://us-central1-okason-contractor-app.cloudfunctions.net/recordStripePayment";
      url = url + `?documentId=${documentId}&amount=${res.amount / 100}`;
      await httpGet(url)
        .then((res) => {
          if (res?.error) {
            alert("Error: " + res.error);
          } else if (res) {
            paymentSuccessfulModal();
          }
          setIsPaying(false);
        })
        .catch((err) => {
          setIsPaying(false);
          alert(err);
        });
    } else {
      alert("Payment in progress");
      setIsPaying(false);
    }
  };

  return (
    <CheckoutBox>
      <form onSubmitCapture={handleSubmit} id={stripe ? "checkout-form" : ""}>
        <CardElement />
      </form>
    </CheckoutBox>
  );
};

const Stripe = (props: any) => {
  return (
    <StripeBox>
      <Elements stripe={stripePromise}>
        <CheckoutForm {...props} />
      </Elements>
    </StripeBox>
  );
};

export default Stripe;
