import React from "react";
import {
  Switch,
  Route,
  // RouteProps
} from "react-router-dom";
import Invoice from "views/invoice";
import Home from "views/home";

// const ProtectedRoute: any = ({
//   component: Component,
//   access,
//   ...rest
// }: {
//   component: React.ComponentType<RouteProps>;
//   access: boolean;
// }) => {
//   return <Route {...rest} render={(props: any) => <Component {...props} />} />;
// };

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Invoice} />
      <Route exact path="/home" component={Home} />
    </Switch>
  );
}
