import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Radio, Modal } from "antd";
import { InvoiceBox } from "./_invoice.styled";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import { httpGet } from "utils";
import moment from "moment";
import axios from "axios";
import fileDownload from "js-file-download";
import { Stripe } from "components";

const currencyFormatter = require("currency-formatter");

// const urls =
//   "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf";

const PdfRender = React.memo(({ invoiceData, pages }: any) => {
  return invoiceData?.pdfUrl && Boolean(pages.length) ? (
    <div className="pdf-container">
      <Document
        renderMode="svg"
        file={{
          url: invoiceData?.pdfUrl,
        }}
      >
        {pages.map((_: any, i: number) => {
          return <Page pageNumber={i + 1} />;
        })}
      </Document>
    </div>
  ) : (
    <div>Loading PDF...</div>
  );
});

const PdfRenderHidden = React.memo(({ invoiceData, setpages }: any) => {
  return (
    invoiceData?.pdfUrl && (
      <div className="hidden-pdf">
        <Document
          file={{
            url: invoiceData?.pdfUrl,
          }}
          onLoadSuccess={(info) => {
            setpages(
              new Array(info._pdfInfo.numPages).fill(info._pdfInfo.numPages)
            );
          }}
        >
          <Page pageNumber={1} />
        </Document>
      </div>
    )
  );
});

type InvoiceDataProps = {
  documentId: string;
  totalAmount: number;
  dueDate: number;
  stripeAccountId: string;
  type: string;
  isStripeEnabled: boolean;
  isPayPalEnabled: boolean;
  pdfUrl: string;
  licenseUrl: string;
  insuranceUrl: string;
  googleUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  yelpUrl: string;
  websiteUrl: string;
  companyName: string;
  documentNumber: string;
  fileName: string;
};

const Invoice = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<number>(1);
  const [invoiceForm] = Form.useForm();
  const location = useLocation();
  const [pages, setpages] = useState(new Array(0).fill(0));
  const [error, setError] = useState("");
  const [invoiceData, setInvoiceData] = useState<InvoiceDataProps>({
    documentId: "",
    totalAmount: 0,
    dueDate: 0,
    stripeAccountId: "",
    type: "",
    isStripeEnabled: true,
    isPayPalEnabled: false,
    pdfUrl: "",
    licenseUrl: "",
    insuranceUrl: "",
    googleUrl: "",
    facebookUrl: "",
    instagramUrl: "",
    yelpUrl: "",
    websiteUrl: "",
    companyName: "",
    documentNumber: "",
    fileName: "",
  });

  const [signatureForm] = Form.useForm();
  const [isPaying, setIsPaying] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isDigitalSignaturing, setIsDigitalSignaturing] =
    useState<boolean>(false);
  const [isSignatureModal, setIsSignatureModal] = useState<boolean>(false);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const url = new URL(
    window.location.origin + location.pathname + location.search
  );
  const documentId = url.searchParams.get("documentId");
  const uid = url.searchParams.get("uid");

  useEffect(() => {
    const effectUrl = new URL(
      window.location.origin + location.pathname + location.search
    );
    const effectDocumentId = effectUrl.searchParams.get("documentId");
    const effectUid = effectUrl.searchParams.get("uid");
    if (effectDocumentId && effectUid) {
      const fetchInvoice = async () => {
        setIsFetching(true);
        await httpGet(
          `https://us-central1-okason-contractor-app.cloudfunctions.net/getReviewDisplayData?documentId=${effectDocumentId}&uid=${effectUid}`
        )
          .then((res) => {
            if (res?.error) {
              setIsFetching(false);
              setError("Unable to generate display data");
            } else if (res) {
              const data = JSON.parse(res) || {};
              setInvoiceData(data);
              setPaymentAmount(data.totalAmount / 100);
              setTimeout(() => {
                invoiceForm.setFieldsValue({
                  paymentAmount: data.totalAmount / 100,
                });
              }, 200);
            }
            setIsFetching(false);
          })
          .catch((err) => {
            setIsFetching(false);
            setError("Something went wrong");
          });
      };
      fetchInvoice();
    }
  }, [location, invoiceForm]);

  const handleRadio = (e: any) => {
    setSelectedPaymentMethod(Number(e.target.value));
  };

  const handlePayNow = () => {
    invoiceForm.submit();
    invoiceForm
      .validateFields()
      .then(async (data: any) => {
        const getCheckoutForm = document.getElementById("checkout-form");
        getCheckoutForm && getCheckoutForm.dispatchEvent(new Event("submit"));
      })
      .catch(() => setError("Something went wrong"));
  };

  const openSignatureModal = () => {
    setIsSignatureModal(true);
  };

  const closeSignatureModal = () => {
    setIsSignatureModal(false);
  };

  const handleDigitalSignature = () => {
    signatureForm.submit();
    signatureForm
      .validateFields()
      .then(async (data: any) => {
        setIsDigitalSignaturing(true);
        let url =
          "https://us-central1-okason-contractor-app.cloudfunctions.net/recordClientSignature";
        url =
          url + `?documentId=${documentId}&uid=${uid}&name=${data?.signature}`;
        await httpGet(url)
          .then(() => {
            alert("Signature added successfully");
            setIsDigitalSignaturing(false);
            closeSignatureModal();
          })
          .catch(() => {
            setIsDigitalSignaturing(false);
            closeSignatureModal();
            setError("Something went wrong");
          });
      })
      .catch(() => setError("Something went wrong"));
  };

  const downloadFile = (url: string, filename: string) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const showAbout =
    invoiceData.licenseUrl ||
    invoiceData.insuranceUrl ||
    invoiceData.googleUrl ||
    invoiceData.facebookUrl ||
    invoiceData.instagramUrl ||
    invoiceData.yelpUrl ||
    invoiceData.websiteUrl;

  return (
    <InvoiceBox>
      {isFetching ? (
        <div className="fetching-loader">Fetching...</div>
      ) : !documentId || error ? (
        <div className="error">
          {error || "The function must be called with a documentId"}
        </div>
      ) : (
        <>
          <div className="main-header">
            <div className="container">
              <div className="header">
                <div className="header-logo">
                  <img src="/images/logo.svg" alt="logo" />
                </div>
                <div className="buttons-group">
                  <Button danger size="large" onClick={openSignatureModal}>
                    <img src="/images/edit.png" alt="edit" />
                    Sign
                  </Button>
                  <Button
                    onClick={() => {
                      axios
                      .get(invoiceData?.pdfUrl, {
                        responseType: "blob",
                      })
                      .then((res) => {
                        var blobURL = URL.createObjectURL(res.data);
                    
                        var iframe =  document.createElement('iframe'); //load content in an iframe to print later
                        document.body.appendChild(iframe);
                    
                        iframe.style.display = 'none';
                        iframe.src = blobURL;
                        iframe.onload = function() {
                          setTimeout(function() {
                            iframe.focus();
                            //@ts-ignore
                            iframe.contentWindow.print();
                          }, 1);
                        };
                      });
                    }}
                  >
                    <img src="/images/print.png" alt="print" />
                    Print
                  </Button>
                  <Button
                    onClick={() =>
                      downloadFile(invoiceData?.pdfUrl, invoiceData.fileName)
                    }
                  >
                    <img src="/images/download.png" alt="download" />
                    Download
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="title">Review & Pay</div>
            <Row gutter={16}>
              <Col className="gutter-row" sm={17}>
                <PdfRenderHidden
                  invoiceData={invoiceData}
                  setpages={setpages}
                />
                <PdfRender invoiceData={invoiceData} pages={pages} />
              </Col>
              <Col className="gutter-row" sm={7}>
                <div className="aside-bar">
                  <div className="aside-bar-top">
                    <Button type="primary" block className="invoice-btn">
                      {invoiceData.type}
                    </Button>
                    <div className="payment">
                      <div className="title">
                        {invoiceData.companyName} . Invoice No:{" "}
                        {invoiceData?.documentNumber}
                      </div>
                      <div className="amount">
                        {currencyFormatter.format(
                          (invoiceData?.totalAmount || 0) / 100,
                          {
                            code: "USD",
                          }
                        )}
                      </div>
                      <div className="due-date">
                        Due Date:{" "}
                        {invoiceData?.dueDate &&
                          moment(invoiceData?.dueDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <Form form={invoiceForm} layout="vertical">
                      <Form.Item
                        name="paymentAmount"
                        label="Enter Payment amount"
                        className="amount-form-item"
                        rules={[
                          {
                            required: true,
                            message: "Please enter amount",
                          },
                        ]}
                      >
                        <Input
                          value={paymentAmount}
                          type="number"
                          onChange={(e) =>
                            setPaymentAmount(Number(e.target.value))
                          }
                          placeholder="$11,678.50"
                        />
                      </Form.Item>
                      <Form.Item
                        name="payment_method"
                        label="Select Payment method"
                        className="amount-form-item"
                      >
                        <Radio.Group
                          onChange={handleRadio}
                          value={selectedPaymentMethod}
                          defaultValue={selectedPaymentMethod}
                        >
                          <>
                            <div className="radio-card">
                              <Radio value={1}>
                                Debit or credit card{" "}
                                <img
                                  src="/images/payment-cards.svg"
                                  alt="cards"
                                />
                              </Radio>
                            </div>
                            <div
                              className={`stripe-card ${
                                selectedPaymentMethod === 1
                                  ? "stripe-card-active"
                                  : ""
                              }`}
                            >
                              {selectedPaymentMethod === 1 && (
                                <Stripe
                                  documentId={documentId}
                                  uid={uid}
                                  paymentAmount={paymentAmount}
                                  setIsPaying={setIsPaying}
                                />
                              )}
                            </div>
                          </>
                          {invoiceData?.isPayPalEnabled && (
                            <div className="radio-card">
                              <Radio value={2}>
                                Paypal{" "}
                                <img src="/images/paypal.svg" alt="paypal" />
                              </Radio>
                            </div>
                          )}
                        </Radio.Group>
                      </Form.Item>
                      <Button
                        type="primary"
                        danger
                        block
                        className="pay-btn"
                        form="checkout-form"
                        onClick={handlePayNow}
                        disabled={isPaying}
                      >
                        {isPaying ? "Payment processing..." : "Pay Now"}
                      </Button>
                    </Form>
                    <div className="aside-logo">
                      <img src="/images/logo2.svg" alt="logo" />
                    </div>
                  </div>
                  {showAbout && (
                    <div className="aside-bar-bottom">
                      <div className="title">ABOUT CARGOMATIC</div>
                      {invoiceData.licenseUrl && (
                        <div className="ticket">
                          <div className="left-side">
                            <img src="/images/license.svg" alt="license" />{" "}
                            License
                          </div>
                          <div
                            className="download-btn"
                            onClick={() =>
                              downloadFile(
                                invoiceData.licenseUrl,
                                "license.pdf"
                              )
                            }
                          >
                            <img src="/images/download2.svg" alt="download" />
                          </div>
                        </div>
                      )}

                      {invoiceData.insuranceUrl && (
                        <div className="ticket">
                          <div className="left-side">
                            <img src="/images/insurance.svg" alt="insurance" />
                            Insurance
                          </div>
                          <div
                            className="download-btn"
                            onClick={() =>
                              downloadFile(
                                invoiceData.insuranceUrl,
                                "insurance.pdf"
                              )
                            }
                          >
                            <img src="/images/download2.svg" alt="download" />
                          </div>
                        </div>
                      )}
                      <div className="social">
                        <div className="logos">
                          {invoiceData.googleUrl && (
                            <div>
                              <a
                                href={invoiceData.googleUrl || "#"}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src="/images/google.svg" alt="google" />
                              </a>
                            </div>
                          )}

                          {invoiceData.facebookUrl && (
                            <div>
                              <a
                                href={invoiceData.facebookUrl || "#"}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="/images/facebook.svg"
                                  alt="facebook"
                                />
                              </a>
                            </div>
                          )}

                          {invoiceData.instagramUrl && (
                            <div>
                              <a
                                href={invoiceData.instagramUrl || "#"}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src="/images/insta.svg" alt="insta" />
                              </a>
                            </div>
                          )}
                          {invoiceData.yelpUrl && (
                            <div>
                              <a
                                href={invoiceData.yelpUrl || "#"}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src="/images/yelp.svg" alt="yelp" />
                              </a>
                            </div>
                          )}
                        </div>
                        {invoiceData.websiteUrl && (
                          <div className="link">
                            <a
                              href={invoiceData.websiteUrl || "#"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {invoiceData?.websiteUrl}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Modal
            title="Digital Signature"
            visible={isSignatureModal}
            onOk={handleDigitalSignature}
            okText={isDigitalSignaturing ? "Saving..." : "Submit"}
            onCancel={() => !isDigitalSignaturing && closeSignatureModal()}
            className="expense-modal"
            maskClosable={false}
            forceRender={true}
            okButtonProps={{ disabled: isDigitalSignaturing }}
            cancelButtonProps={{ disabled: isDigitalSignaturing }}
          >
            <Form form={signatureForm} layout="vertical">
              <Form.Item
                name="signature"
                label="Please enter you name"
                rules={[
                  {
                    required: true,
                    message: "Please enter you name!",
                  },
                ]}
              >
                <Input type="text" placeholder="Please enter you name" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}{" "}
    </InvoiceBox>
  );
};

export default Invoice;
