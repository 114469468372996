import axios from "axios";

/**
 * Cancel Token
 */
const { CancelToken } = axios;
/**
 * Use to cancel Http Requests
 */
let cancelHttpTokens = [];
/**
 * Helper Params used in Request
 */
const HELPER_PARAMS = {
  callback: null, // Function|Null
  headers: {}, // Additional Headers
  traceName: "untraced_event",
};
/**
 * Get Common Headers
 *
 * @param {String} url
 * @param {Object} additional_headers
 *
 * @return {Object} Headers
 */
export const getCommonHeaders = (url: any, additional_headers: any) => {
  try {
    const { traceName } = additional_headers || {};
    const light_step_headers = {
      "x-api-trace-name": traceName,
    };

    let headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Cache-Control": "no-cache",
      ...light_step_headers,
    };

    headers = { ...headers, ...additional_headers };

    return headers;
  } catch (e) {
    return {};
  }
};
/**
 * Extract JSON Response
 *
 * @param {JSON} json [JSON Data]
 *
 * @return {Object|String} Extarcted value or Blank Object
 */
export const extractJSON = (json: any) => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return "";
  }
};
/**
 * Handle Success Response
 *
 * @param {Object|Null} res
 *
 * @return {Object|Null}
 */
export const httpHandleResponse = (res: any) => {
  cancelHttpTokens = [];
  if (!res) return Promise.reject(null);
  const r = res.data;
  return Promise.resolve(r);
};
/**
 * Handle API Error Reponse
 *
 * @param {Object|Null} error
 *
 * @return {Object|String|Null}
 */
export const httpHandleError = (error: any) => {
  /* error = { error, config, code, request, response } */
  try {
    if (!error) return Promise.reject({});
    /* Handle Cancel Request */
    cancelHttpTokens = [];
    if (!error.request) return Promise.reject("cancelled");
    const xhr = error.response.data;
    let err = { error: "", response: "" };
    if (xhr) err = xhr;
    if (xhr) {
      switch (xhr.status) {
        case 0:
          console.log("Internal Error");
          break;
        case 400:
          console.log(err?.error);
          break;
        case 401:
          console.log("Unauthorized");
          break;
        case 403:
          console.log("Unauthorized");
          break;
        case 404:
          console.log(err?.response);
          break;

        case 502:
          console.log("Bad gateway");
          break;
        case 503:
          if (err.error && typeof err.error == "string") {
            console.log(err.error);
          } else {
            console.log("Bad gateway");
          }
          break;
        default:
      }
    } else {
      console.log("Internal Error");
    }
    return xhr;
  } catch (e) {
    console.error("-- HTTP HANDLE ERROR -- ", e);
    return Promise.reject({});
  }
};
/**
 * GET Request
 *
 * @param {String} url
 * @param {Object} `HELPER_PARAMS`
 */
export const httpGet = async (
  url: any,
  { callback, headers, traceName }: any = HELPER_PARAMS
) => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);
    if (!traceName) ({ traceName } = HELPER_PARAMS);
    headers.traceName = traceName;

    return axios
      .get(url, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then((res) => {
        return httpHandleResponse(res);
      })
      .catch((err) => {
        return httpHandleError(err);
      });
  } catch (e) {
    console.error("-- HTTP GET -- ", e);
    return Promise.reject({});
  }
};
/**
 * POST Request
 *
 * @param {String} url
 * @param {Object} params
 * @param {Object} `HELPER_PARAMS`
 */
export const httpPost = (
  url: any,
  params: any,
  { callback, headers, traceName }: any = HELPER_PARAMS
) => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);
    if (!traceName) ({ traceName } = HELPER_PARAMS);
    headers.traceName = traceName;
    return axios
      .post(url, params, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then((res) => {
        return httpHandleResponse(res);
      })
      .catch((err) => {
        return httpHandleError(err);
      });
  } catch (e) {
    console.error("-- HTTP POST -- ", e);
    return Promise.reject({});
  }
};
/**
 * PUT Request
 *
 * @param {String} url
 * @param {Object} params
 * @param {Object} `HELPER_PARAMS`
 */
export const httpPut = (
  url: any,
  params: any,
  { callback, headers, traceName }: any = HELPER_PARAMS
) => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);
    if (!traceName) ({ traceName } = HELPER_PARAMS);
    headers.traceName = traceName;
    return axios
      .put(url, params, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then((res) => {
        return httpHandleResponse(res);
      })
      .catch((err) => {
        return httpHandleError(err);
      });
  } catch (e) {
    console.error("-- HTTP PUT -- ", e);
    return Promise.reject({});
  }
};
/**
 * DELETE Request
 *
 * @param {String} url
 * @param {Object} `HELPER_PARAMS`
 */
export const httpDelete = (
  url: any,
  { callback, headers, traceName }: any = HELPER_PARAMS
) => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);
    if (!traceName) ({ traceName } = HELPER_PARAMS);
    headers.traceName = traceName;
    return axios
      .delete(url, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then((res) => {
        return httpHandleResponse(res);
      })
      .catch((err) => {
        return httpHandleError(err);
      });
  } catch (e) {
    console.error("-- HTTP DELETE -- ", e);
    return Promise.reject({});
  }
};
